<template>
  <div class="agents-board-header">
    <base-select-tag v-model="sorting"
                     :label="$t('agentsBoard.agentsBoardHeader.sortingLabel')"
                     :options="sortingOptions"></base-select-tag>
  </div>
</template>

<script>
  const defaultSorting = 'look_count{desc}'

  export default {
    data() {
      return {
        sorting: {},
        sortingOptions: [
          {
            label: this.$t('agentsBoard.agentsBoardHeader.looksSorting'),
            value: 'look_count{desc}'
          },
          {
            label: this.$t('agentsBoard.agentsBoardHeader.likesSorting'),
            value: 'likes_count{desc}'
          }
        ]
      }
    },

    created() {
      let sortingOption = this.sortingOptions.find(sorting => sorting.value === defaultSorting)
      this.sorting = _.cloneDeep(sortingOption)
    },

    watch: {
      sorting: {
        handler: function(sorting) {
          let query = Object.assign({}, this.$route.query, { sorting: sorting.value })
          this.$router.push({ route: 'agentsBoard', query })
        },
        immediate: true
      }
    }
  }
</script>

<style lang="scss" scoped>
  .agents-board-header {
    margin: 20px 0;
  }
</style>