<template>
  <slide class="agents-board-item-looks-slide">
    <div class="header">
      <div class="title-and-date">
        <router-link :to="{ name: 'look', params: { userId: look.authorId, lookId: look.id} }"
                     class="title">
          {{ look.title }}
        </router-link>
        <div class="date">12.04</div>
      </div>
      <div class="share" ref="share">
        <base-icon icon="share" color="#797979"></base-icon>
        <base-look-share :look="look" ref="shareDropdown"/>
      </div>
    </div>
    <div class="look-preview">
      <img :src="look.previewUrl">
    </div>
  </slide>
</template>

<script>
  import { Slide } from 'vue-carousel'

  export default {
    props: {
      look: {
        required: true,
        type: Object
      }
    },

    components: {
      Slide
    },

    mounted() {
      tippy(this.$refs.share, {
        content: this.$refs.shareDropdown.$el,
        interactive: true,
        theme: 'light dropdown-menu share',
        animation: 'shift-away',
        placement: 'bottom',
        arrow: true,
        duration: [600, 300],
        inertia: true
      })
    }
  }
</script>

<style lang="scss">
  .agents-board-item-looks-slide {
    position: relative;
    overflow: hidden;

    .header {
      display: flex;
      align-items: center;
      width: 100%;
      padding: 20px;

      .title-and-date {
        .title {
          color: #000;
          font-family: "Cormorant Garamond", serif;
          font-size: 20px;
          width: 210px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .date {
          color: #797979;
          font-size: 12px;
        }
      }

      .share {
        flex-basis: 40px;
        margin-left: auto;
        text-align: right;
      }
    }

    .look-preview {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;

      img {
        width: 200px;
        height: 200px;
      }
    }
  }
</style>
