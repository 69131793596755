<template>
  <div class="agents-board container">
    <agents-board-header></agents-board-header>
    <div class="agents-list" v-infinite-scroll="loadMore">
      <agents-board-item v-for="agent in agents"
                           :key="agent.id"
                           :agent="agent"
                           @sendMessageClick="onSendMessageClick(agent)"></agents-board-item>
    </div>
    <portal to="modals">
      <agents-board-send-message-modal :show="sendMessageModal.show"
                                         :agent="sendMessageModal.agent"
                                         @close="sendMessageModal.show = false">
      </agents-board-send-message-modal>
    </portal>
  </div>
</template>

<script>
  import profileService from '@/services/queries/profileQueries.js'
  import AgentsBoardItem from './AgentsBoardItem.vue'
  import AgentsBoardSendMessageModal from './AgentsBoardSendMessageModal.vue'
  import AgentsBoardHeader from './AgentsBoardHeader.vue'

  const lookNumberInGallery = 5

  export default {
    components: {
      AgentsBoardHeader,
      AgentsBoardItem,
      AgentsBoardSendMessageModal
    },

    data() {
      return {
        loading: false,
        agents: [],

        limit: 50,
        offset: 0,
        total: 0,

        sendMessageModal: {
          show: false,
          agent: {}
        }
      }
    },

    created() {
      this.loadAgents()
    },

    // TODO: These is probable temporary solution, a better choice
    // would be to implement something like this: https://github.com/nikolaynesov/vue-body-class
    mounted() {
      document.body.classList.add('has-background')
    },

    destroyed() {
      document.body.classList.remove('has-background')
    },

    methods: {
      loadAgents(append = false) {
        let params = Object.assign({}, this.$route.query)

        params.user_type = this.constants$.userTypes.agent
        params.limit = this.limit
        params.offset = this.offset
        params.with_looks_count = lookNumberInGallery
        params['look_count.min'] = 1

        this.loading = true

        profileService.get(params).then(data => {
          this.total = data.total_count

          let agents = data.items

          if (append) {
            this.agents.push(...agents)
          } else {
            this.agents = agents
          }

          this.loading = false
        })
      },

      loadMore() {
        if (this.loading) {
          return false
        }

        this.offset = this.offset + this.limit

        if (this.offset > this.total) {
          return
        }

        this.loadFeed(true)
      },

      onSendMessageClick(agent) {
        this.sendMessageModal = {
          show: true,
          agent
        }
      }
    },

    watch: {
      '$route' (to, from) {
        this.loadAgents()
      }
    }
  }
</script>

<style lang="scss">

</style>