<template>
  <base-modal :show="show" @close="$emit('close')">
    <template slot="header">
      {{ $t('agentsBoard.agentsBoardSendMessageModal.title', { agentName: agent.full_name }) }}
    </template>
    <base-text-box ref="message"
                   rules="required"
                   v-model="message"
                   :multi-line="true"
                   :show-validation-error="true"
                   :label="$t('agentsBoard.agentsBoardSendMessageModal.messageLabel')"></base-text-box>
    <template slot="footer">
      <base-button @click="submit" :loading="loading">
        {{ $t('agentsBoard.agentsBoardSendMessageModal.sendMessageButton') }}
      </base-button>
    </template>
  </base-modal>
</template>

<script>
  import profileService from '@/services/queries/profileQueries.js'

  export default {
    props: {
      show: {
        required: true,
        type: Boolean
      },

      agent: {
        required: true,
        type: Object
      }
    },

    data() {
      return {
        message: '',
        loading: false
      }
    },

    methods: {
      submit() {
        this.loading = true

        let refs = ['message']

        let promises = []
        refs.forEach((ref) => {
          promises.push(this.$refs[ref].validate())
        })

        Promise.all(promises).then((results) => {
          let notValid = results.some((success) => {
            return !success
          })

          if (notValid) {
            this.loading = false
            return
          }

          profileService.sendMessage(this.agent.id, this.message).then((response) => {
            this.$emit('close')
            this.$swal({
              title: this.$t('agentsBoard.agentsBoardSendMessageModal.successTitle'),
              text:  this.$t('agentsBoard.agentsBoardSendMessageModal.successMessage'),
              confirmButtonText: this.$t('agentsBoard.agentsBoardSendMessageModal.successConfirmButton'),
              type: 'success'
            })
          }).catch((error) => {
            this.loading = false
          })
        })
      },
    }
  }
</script>

<style lang="scss">

</style>
