7y<template>
  <div class="agent-board-item-info" :class="$mq">
    <div class="header">
      <div class="left">
        <base-avatar :user="agent" width="64px" height="64px"></base-avatar>
      </div>
      <div class="right">
        <div class="name">
          {{ agent.fullName }}
          <img :src="agent.country.images['small'].url"
               :title="agent.country.title"
               v-if="agent.country">
        </div>
        <div class="rating-and-reviews">
          <star-rating :rating="agent.averageRating"
                       :star-size="15"
                       :show-rating="false"
                       :inline="true"
                       :read-only="true"
                       :star-points="[23,2, 14,17, 0,19, 10,34, 7,50, 23,43, 38,50, 36,34, 46,19, 31,17]"
                       :rounded-corners="false"></star-rating>
          <div class="reviews">
            {{ $tc('agentsBoard.agentsBoardItemInfo.reviewsNumber',
              agent.reviewsNumber, { count: filterNumber(agent.reviewsNumber) }) }}
          </div>
        </div>
      </div>
    </div>
    <div class="description" :class="$mq">
      <template v-if="!agent.description && !agent.education">
        {{ $t('agentsBoard.agentsBoardItemInfo.noDescription') }}
      </template>

      <p v-if="agent.description">{{ agent.description }}</p>
      <p v-if="agent.education">
        {{ $t('agentsBoard.agentsBoardItemInfo.education', { education: agent.education }) }}
      </p>
    </div>
    <div class="bottom" :class="$mq">
      <div class="more">
        <base-button :square="true"
                     size="small"
                     class="message-button"
                     @click="onSendMessageClick">
          {{ $t('agentsBoard.agentsBoardItemInfo.sendMessage') }}
        </base-button>
      </div>
      <div class="counters">
        <div class="followers">
          {{ $tc('agentsBoard.agentsBoardItemInfo.followersNumber',
            5, { count: filterNumber(5) }) }}
        </div>
        <div class="likes">
          <base-icon icon="heart"></base-icon> <span>{{ filterNumber(agent.likesNumber) }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { authenticated } from '@/services/auth.js'
  import StarRating from 'vue-star-rating'

  // нет подписчиков, 1 подписчик, 2 подписчика, 5 подписчиков, 13 подписчиков
  export default {
    props: {
      agent: {
        required: true,
        type: Object
      }
    },

    components: {
      StarRating
    },

    methods: {
      filterNumber(number) {
        return this.$options.filters.number(number)
      },

      onSendMessageClick() {
        if (!authenticated()) {
          if (this.$mq === 'mobile' || this.$mq === 'tablet') {
            this.$router.push({ name: 'login' })
          } else {
            this.openAuthModal({content: 'login'})
          }
          return
        }

        this.$emit('sendMessageClick')
      },

      ...mapActions('system', [
        'openAuthModal'
      ])
    }
  }
</script>

<style lang="scss">
  .agent-board-item-info {
    display: flex;
    flex-direction: column;
    flex: 1 0;
    max-height: 300px;
    padding: 20px;

    .header {
      display: flex;
      align-items: center;
      margin-bottom: 30px;

      .left {
        flex: 0 0 80px;
      }

      .right {
        display: flex;
        flex-direction: column;

        .name {
          display: flex;
          align-items: center;
          margin-bottom: 10px;
          font-size: 16px;
          max-width: 500px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;

          & > img {
            height: 24px;
            margin-left: 10px;
          }
        }

        .rating-and-reviews {
          display: flex;
          align-items: center;
        }

        .reviews {
          font-size: 12px;
          color: #797979;
          margin-left: 10px;
        }
      }
    }

    .description {
      font-size: 14px;
      color: #797979;

      height: 120px;
      overflow: auto;

      &.mobile {
        height: auto;
        margin-bottom: 20px;
      }
    }

    .bottom {
      display: flex;
      align-items: flex-end;
      margin-top: auto;

      .counters {
        display: flex;
        margin-left: auto;
        color: #797979;
        font-size: 14px;

        .likes {
          display: flex;
          align-items: center;
          margin-left: 20px;

          & > span {
            margin-left: 4px;
          }
        }
      }

      &.mobile {
        flex-direction: column;
        align-items: stretch;

        .message-button {
          width: 100%;
        }

        .counters {
          margin: 20px 0 0 0;
        }
      }
    }
  }
</style>