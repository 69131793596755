<template>
  <div class="agents-board-item" :class="$mq">
    <agents-board-item-looks :looks="agent.looks"></agents-board-item-looks>
    <agents-board-item-info :agent="agent" @sendMessageClick="$emit('sendMessageClick')"></agents-board-item-info>
  </div>
</template>

<script>
  import AgentsBoardItemLooks from './AgentsBoardItemLooks.vue'
  import AgentsBoardItemInfo from './AgentsBoardItemInfo.vue'

  export default {
    props: {
      agent: {
        required: true,
        type: Object
      }
    },

    components: {
      AgentsBoardItemLooks,
      AgentsBoardItemInfo
    }
  }
</script>

<style lang="scss">
  .agents-board-item {
    display: flex;
    margin-bottom: 40px;
    background: #fff;
    box-shadow: 0 10px 20px 0 rgba(105, 129, 211, 0.13);

    &.mobile {
      margin: 20px;
      flex-direction: column;

      .looks {
        height: 200px;
        width: 100%;
        margin: 0 auto;
      }
    }
  }
</style>