<template>
  <div class="agents-board-item-looks">
    <carousel :per-page="1" v-if="looks.length > 0" class="carousel">
      <agent-board-item-looks-slide v-for="look in looks"
                                      :key="look.id"
                                      :look="look">
      </agent-board-item-looks-slide>
    </carousel>
  </div>
</template>

<script>
  import { Carousel } from 'vue-carousel'
  import AgentBoardItemLooksSlide from './AgentsBoardItemLooksSlide.vue'

  export default {
    props: {
      looks: {
        required: true,
        type: Array
      }
    },

    components: {
      Carousel,
      AgentBoardItemLooksSlide
    }
  }
</script>

<style lang="scss">
  .agents-board-item-looks {
    height: 300px;
    width: 300px;

    .carousel {
      height: 100%;
      width: 100%;

      .VueCarousel-wrapper {
        width: 100%;
        height: 100%;
        position: relative;
      }

      .VueCarousel-pagination {
        position: absolute;
        bottom: 10px;
      }
    }
  }
</style>